<template>
  <div class="form-content-container">
    <Form class="form-container">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入标准名称(5-30字)"
            v-model.trim="formValidate.title"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.title.length }}/30</div>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入标准号"
          v-model.trim="formValidate.standardNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Select
          v-model="formValidate.standardNature"
          placeholder="请选择标准性质"
        >
          <Option value="推荐性">推荐性</Option>
          <Option value="强制性">强制性</Option>
        </Select>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Select
          v-model="formValidate.standardStatus"
          placeholder="请选择标准状态"
        >
          <Option value="现行">现行</Option>
          <Option value="即将实施">即将实施</Option>
        </Select>
        <span class="xing">*</span>
      </div>
      <!--  -->
      <div class="form-item">
        <Input
          placeholder="请输入关键词，多个关键词之间用英文逗号“,”隔开"
          v-model.trim="formValidate.keyword"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="textarea"
          :rows="4"
          placeholder="请输入摘要"
          v-model.trim="formValidate.artSummary"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="date"
          placeholder="请选择实施日期"
          style="width: 200px"
          @on-change="changeTime"
          :value="formValidate.implementationTime"
          :editable="false"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="date"
          placeholder="请选择发布日期"
          style="width: 200px"
          @on-change="changeTime1"
          :value="formValidate.publishTime"
          :editable="false"
          :options="endStartStr"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入主管部门"
          v-model.trim="formValidate.competentDep"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入发布单位"
          v-model.trim="formValidate.publishUnit"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入归口单位"
          v-model.trim="formValidate.putUnderUnit"
        />
        <span class="xing">*</span>
      </div>
      <!-- 起草人 -->
      <Drafter
        @autherInfo1="handleAuther1"
        :formValidate="formValidate"
      ></Drafter>
      <div class="form-item">
        <Input
          placeholder="请输入起草单位"
          v-model.trim="formValidate.draftUnit"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入标准技术委员会"
          v-model.trim="formValidate.technicalCommittee"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入中国标准分类号"
          v-model.trim="formValidate.chinaClassifyNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入国际分类号"
          v-model.trim="formValidate.internationalClassifyNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="number"
          placeholder="请输入总页数"
          v-model.trim="formValidate.pageCount"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="number"
          placeholder="请输入纸质版定价"
          v-model.trim="formValidate.paperPricing"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item" @click="handleFileDel">
        <div class="upload">
          <label for="">附件</label>
          <Upload
            type="drag"
            :action="actionUrl"
            :format="['pdf']"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            accept=".pdf"
            :show-upload-list="false"
          >
            <Button>上传</Button>
            <p style="margin-left: 7px" v-if="!formValidate.accessoryName">
              请上传pdf格式文件
            </p>
            <p style="margin-left: 7px" v-else>
              {{ formValidate.accessoryName }}
            </p>
          </Upload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.externalUrl"
          placeholder="请输入外部链接便于平台审核"
        />
      </div>
      <div class="form-item">
        <div class="checkbox">
          <label for="">发布栏目</label>
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(4, '/standardpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000"
          >保存</Button
        >
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000"
          >发布</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Drafter from "../components/Drafter.vue";
import { mapState } from "vuex";
import preview from "@/mixins/preview.js";
export default {
  components: {
    Drafter,
  },
  mixins: [preview],
  created() {
    // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  data() {
    return {
      tempAutherList: [],
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      formValidate: {
        title: "",
        standardNumber: "",
        standardNature: "",
        standardStatus: "",
        keyword: "",
        artSummary: "",
        implementationTime: "",
        publishTime: "",
        competentDep: "",
        publishUnit: "",
        putUnderUnit: "",
        articleAuthorList: [],
        draftUnit: "",
        technicalCommittee: "",
        chinaClassifyNumber: "",
        internationalClassifyNumber: "",
        pageCount: "",
        paperPricing: "",
        filePath: "", // 文件路径
        accessoryName: "", // 文件名称
        externalUrl: "",
        synchronizedStatus: false,
        autherList: [
          {
            authorName: "",
            showSelect1: false,
            unitDisabled1: true,
            authorId: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["学者空间"];
      }
      return arr;
    },
    endStartStr() {
      return {
        disabledDate: (data) => {
          let d = new Date(data).valueOf();
          return data && new Date().valueOf() < d;
        },
      };
    },
  },
  methods: {
    verification(formValidate) {
      if (this.formValidate.title.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      for (let i = 0; i < formValidate.articleAuthorList.length; i++) {
        const element = formValidate.articleAuthorList[i];
        if (!this.$route.query.id && element.authorName == "") {
          this.$Message.error("起草人不能为空");
          return false;
        }
      }
      if (formValidate.chinaClassifyNumber.length > 40) {
        this.$Message.error("中国标准分类号已达上限");
        return false;
      }
      if (formValidate.internationalClassifyNumber.length > 40) {
        this.$Message.error("国际分类号已达上限");
        return false;
      }
      if (formValidate.standardNumber.length > 50) {
        this.$Message.error("标准号已达上限");
        return false;
      }
      if (formValidate.keyword.length > 80) {
        this.$Message.error("关键词已达上限");
        return false;
      }
      if (formValidate.artSummary.length > 1000) {
        this.$Message.error("摘要已达上限");
        return false;
      }
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~])+$/;
      if (!reg.test(formValidate.externalUrl) && formValidate.externalUrl !== '') {
        this.$Message.error("外部链接格式不正确");
        return false;
      }
      // 分隔
      if (/^,|[，。？！.?!]+|(,,)+|,$/.test(formValidate.keyword)) {
        this.$Message.error("多个关键词，只能以英文逗号分隔");
        return false;
      } else {
        let arr = formValidate.keyword.split(",");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == "") {
            this.$Message.error("关键词中存在多余英文逗号");
            return false;
          }
        }
      }
      if (formValidate.artSummary.length > 2500) {
        this.$Message.error("内容简介超出限制");
        return false;
      }
      return true;
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 4,
      });
      const r = resp.data.data;
      for (let j = 0; j < r.draftUser.split(",").length - 1; j++) {
        f.autherList.push({
          authorName: "",
          showSelect1: false,
          unitDisabled1: true,
          authorId: "",
        });
      }
      for (let j = 0; j < r.draftUser.split(",").length; j++) {
        f.autherList[j].authorName = r.draftUser.split(",")[j];
        f.autherList[j].authorId = r.draftUserId.split(",")[j];
      }
      this.tempAutherList = f.autherList;
      f.title = r.standardName;
      f.standardNumber = r.standardNumber;
      f.standardNature = r.standardNature;
      f.standardStatus = r.standardStatus;
      f.keyword = r.keyword;
      f.artSummary = r.artSummary;
      f.implementationTime = r.implementationTime;
      f.publishTime = r.publishTime;
      f.competentDep = r.competentDep;
      f.publishUnit = r.publishUnit;
      f.putUnderUnit = r.putUnderUnit;
      f.draftUnit = r.draftUnit;
      f.technicalCommittee = r.technicalCommittee;
      f.chinaClassifyNumber = r.chinaClassifyNumber;
      f.internationalClassifyNumber = r.internationalClassifyNumber;
      f.pageCount = r.pageCount;
      f.paperPricing = r.paperPricing;
      f.filePath = r.filePath; // 文件路径
      f.accessoryName = r.accessoryName; // 文件名称
      f.externalUrl = r.externalUrl;
      f.synchronizedStatus = r.synchronizedStatus == 1 ? true : false;
    },
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return false;
      }
      if (!this.verification(formValidate)) {
        return false;
      }
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 4,
        articleId: this.$route.query.id || this.previewData.articleId,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        standardName: formValidate.title,
        standardNumber: formValidate.standardNumber,
        standardNature: formValidate.standardNature,
        standardStatus: formValidate.standardStatus,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        implementationTime: formValidate.implementationTime,
        publishTime: formValidate.publishTime,
        competentDep: formValidate.competentDep,
        publishUnit: formValidate.publishUnit,
        putUnderUnit: formValidate.putUnderUnit,
        draftUnit: formValidate.draftUnit,
        technicalCommittee: formValidate.technicalCommittee,
        chinaClassifyNumber: formValidate.chinaClassifyNumber,
        internationalClassifyNumber: formValidate.internationalClassifyNumber,
        pageCount: formValidate.pageCount,
        paperPricing: formValidate.paperPricing,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        externalUrl: formValidate.externalUrl,
        synchronizedStatus: formValidate.synchronizedStatus == 1 ? true : false,
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList,
      });
      if (resp.status === 200) {
        this.previewData = resp.data.data;
        this.$Message.success(resp.data.resultDesc);
      } else {
        this.$Message.error(resp.data.resultDesc);
      }
      this.disableFlag = false;
      return true;
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (!this.verification(formValidate)) {
        return;
      }
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 4,
        articleId: this.$route.query.id || this.previewData.articleId,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        standardName: formValidate.title,
        standardNumber: formValidate.standardNumber,
        standardNature: formValidate.standardNature,
        standardStatus: formValidate.standardStatus,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        implementationTime: formValidate.implementationTime,
        publishTime: formValidate.publishTime,
        competentDep: formValidate.competentDep,
        publishUnit: formValidate.publishUnit,
        putUnderUnit: formValidate.putUnderUnit,
        draftUnit: formValidate.draftUnit,
        technicalCommittee: formValidate.technicalCommittee,
        chinaClassifyNumber: formValidate.chinaClassifyNumber,
        internationalClassifyNumber: formValidate.internationalClassifyNumber,
        pageCount: formValidate.pageCount,
        paperPricing: formValidate.paperPricing,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        externalUrl: formValidate.externalUrl,
        synchronizedStatus: formValidate.synchronizedStatus == 1 ? true : false,
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList,
      });
      this.clearField(); // 清空
      if (resp.status === 200) {
        this.$Message.success(resp.data.resultDesc);
      }
      this.$router.push("/user"); //
      console.log(resp);
    },
    handleAuther1(list) {
      this.formValidate.articleAuthorList = list;
    },
    changeTime(obj) {
      this.formValidate.implementationTime = obj;
    },
    changeTime1(obj) {
      this.formValidate.publishTime = obj;
    },
    // 文件删除处理
    handleFileDel(e) {
      if (
        e.target.className ===
        "ivu-icon ivu-icon-ios-close ivu-upload-list-remove"
      ) {
        this.formValidate.filePath = ""; // 文件路径
        this.formValidate.accessoryName = ""; // 文件名称
      }
    },
    handleSuccess(res, file) {
      const MaxSize = 1024 * 1024 * 20;
      if (file.size > MaxSize) {
        this.$Message.error("文件太大，请重新上传");
      }
      this.formValidate.filePath = res.content.url;
      this.formValidate.accessoryName = file.name;
    },
    changeCheck(item) {
      this.tempArr = item;
    },
    clearField() {
      // 清空
      this.formValidate.title = "";
      this.formValidate.standardNumber = "";
      this.formValidate.standardNature = "";
      this.formValidate.standardStatus = "";
      this.formValidate.keyword = "";
      this.formValidate.artSummary = "";
      this.formValidate.implementationTime = "";
      this.formValidate.publishTime = "";
      this.formValidate.competentDep = "";
      this.formValidate.publishUnit = "";
      this.formValidate.putUnderUnit = "";
      this.formValidate.draftUnit = "";
      this.formValidate.technicalCommittee = "";
      this.formValidate.chinaClassifyNumber = "";
      this.formValidate.internationalClassifyNumber = "";
      this.formValidate.pageCount = "";
      this.formValidate.paperPricing = "";
      this.formValidate.filePath = ""; // 文件路径
      this.formValidate.accessoryName = ""; // 文件名称
      this.formValidate.externalUrl = "";
      this.formValidate.synchronizedStatus = "";
    },
    isEmpty(f) {
      if (
        f.title === "" &&
        f.standardNumber === "" &&
        f.standardNature === "" &&
        f.standardStatus === "" &&
        f.keyword === "" &&
        f.publishTime === "" &&
        f.competentDep === "" &&
        f.publishUnit === "" &&
        f.putUnderUnit === "" &&
        f.draftUnit === "" &&
        f.technicalCommittee === "" &&
        f.chinaClassifyNumber === "" &&
        f.internationalClassifyNumber === "" &&
        f.pageCount === "" &&
        f.paperPricing === "" &&
        f.filePath === "" && // 文件路径
        f.accessoryName === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      } else if (f.title === "") {
        this.$Message.error("标题不能为空");
        return true;
      } else if (f.keyword === "") {
        this.$Message.error("关键词不能为空");
        return true;
      } else if (f.artSummary === "") {
        this.$Message.error("摘要不能为空");
        return true;
      } else if (f.accessoryName === "") {
        this.$Message.error("附件不能为空");
        return true;
      } else if (f.standardNumber === "") {
        this.$Message.error("标准号不能为空");
        return true;
      } else if (f.standardNature === "") {
        this.$Message.error("标准性质不能为空");
        return true;
      } else if (f.standardStatus === "") {
        this.$Message.error("标准状态不能为空");
        return true;
      } else if (f.publishTime === "") {
        this.$Message.error("发布日期不能为空");
        return true;
      } else if (f.competentDep === "") {
        this.$Message.error("主管部门不能为空");
        return true;
      } else if (f.publishUnit === "") {
        this.$Message.error("发布单位不能为空");
        return true;
      } else if (f.putUnderUnit === "") {
        this.$Message.error("归口单位不能为空");
        return true;
      } else if (f.draftUnit === "") {
        this.$Message.error("起草单位不能为空");
        return true;
      } else if (f.technicalCommittee === "") {
        this.$Message.error("标准技术委员会不能为空");
        return true;
      } else if (f.chinaClassifyNumber === "") {
        this.$Message.error("中国标准分类号不能为空");
        return true;
      } else if (f.internationalClassifyNumber === "") {
        this.$Message.error("国际分类号不能为空");
        return true;
      } else if (f.pageCount === "") {
        this.$Message.error("总页数不能为空");
        return true;
      } else if (f.paperPricing === "") {
        this.$Message.error("纸质版定价不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree ul li {
  margin: 0;
}
::v-deep .ivu-tree-children li {
  display: flex;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
::v-deep .ivu-tree-children li .ivu-tree-children li {
  margin: 30px 40px 0 -60px;
}
::v-deep .ivu-tree-children li .ivu-tree-title {
  width: 100px;
}
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-upload ::v-deep .ivu-upload-drag {
  border: none;
  display: flex;
  align-items: center;
}
::v-deep .ivu-upload {
  height: 38px;
  display: flex;
  align-items: center;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  width: 1200px;
  min-height: 1228px;
  background-color: #fff;
  padding: 60px 100px 0;
  margin: 4px auto 30px;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .form-item {
    width: 100%;
    min-height: 85px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 299px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 323px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 374px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-right: 20px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
      label {
        margin-right: 20px;
      }
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
